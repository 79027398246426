<template>
    <div class="login-main">
        <nav-bar></nav-bar>
        <section class="orders-status-start  box-flex">
            <div class="container review-main text-capitalize position-relative  box-flex__wrapper">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <div class="center-card | d-flex flex-column ">
                            <div class="center-card-box | p-3 top-sticky border-bottom">
                                <div class="resturant-details">
                                    <h4 class="text-start">{{ $t('Subscriptions') }}</h4>
                                </div>
                            </div>
                            <div class="center-card-box | p-3 flex-grow-1 position-relative">
                                <div class="orders-tab-main subscripiton-item">
                                    <b-tabs content-class="my-3" lazy>
                                        <b-tab :title="'Active'" active @click="changeStep(0)">
                                            <active :selectTab="step" />
                                        </b-tab>
                                        <b-tab :title="'Pause'" @click="changeStep(1)">
                                            <paused :selectTab="step" />
                                        </b-tab>
                                        <b-tab :title="'Past'" @click="changeStep(2)">
                                            <cancelled :selectTab="step" />
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Active from '../components/subscriptions/Active.vue';
import Paused from '../components/subscriptions/Paused.vue';
import Cancelled from '../components/subscriptions/Cancelled.vue';
import Footer from '../components/Footer.vue';

export default {
    components: {
        NavBar,
        Active,
        Paused,
        Cancelled,
        Footer
    },
    data() {
        return {
            step: 0, // Initialize step as a number
        }
    },
    methods: {
        changeStep(newStep) {
            this.step = newStep;
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.$auth && !this.$auth.user) {
                this.$router.push({ name: "sign-in" });
            }
        }, 500);
    }
};
</script>